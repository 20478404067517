#reg {
  background: url(../assets/53.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* background-attachment: fixed; */
}

.bg {
  background-image: url(../assets/red-wooden-background.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* background-attachment: fixed; */
}
